<template>
  <div class="fora-device">
    <HeaderMenu/>
    <div class="container-fluid py-4">
      <div class="card">
        <div class="card-body">
          <form style="margin-bottom: 20px;" @submit.prevent="submitEcgReportForm">
            <div class="mb-3">
              <label for="bedNo" class="form-label fw-bold">{{ $t("__residentName") }}</label>
              <div class="col-sm-6 col-md-4">
                <select class="form-select" v-model="dailyForm.resident_id">
                  <option :value="null">{{ $t('__openThisSelectMenuResident') }}</option>
                  <template v-for="data in resident">
                    <option :key="data.id" :value="data.id" v-if="data.name" >
                      {{ ((data.device === null) ? '' : data.device.bed_number) + " / " + data.name }}
                    </option>
                  </template>
                </select>
              </div>
            </div>

            <div class="mb-3">
              <label class="form-label fw-bold">{{ $t('__date') }}</label>
              <div class="d-flex align-items-center flex-wrap">
                <div class="d-inline-block pe-sm-4 mb-2">
                  <VueCtkDateTimePicker
                    :label="$t('__startDate')"
                    v-model="dailyForm.start_date"
                    only-date
                    format="YYYY-MM-DD"
                    formatted="YYYY-MM-DD"
                    color="#2CBDC0"
                    button-color="#2CBDC0"
                    :max-date="new Date().format('yyyy-MM-dd')"
                    :min-date="new Date().addDays(-29).format('yyyy-MM-dd')"
                  />
                  <div class="text-danger" v-if="is_invalid.start_date">
                    {{ feedback_message.start_date }}
                  </div>
                </div>
                <div class="d-inline-block pe-sm-4 mb-2">
                  <VueCtkDateTimePicker
                    :label="$t('__endDate')"
                    v-model="dailyForm.end_date"
                    only-date
                    format="YYYY-MM-DD"
                    formatted="YYYY-MM-DD"
                    color="#2CBDC0"
                    button-color="#2CBDC0"
                    :max-date="new Date().format('yyyy-MM-dd')"
                    :min-date="dailyForm.start_date"
                  />
                  <div class="text-danger" v-if="is_invalid.end_date">
                    {{ feedback_message.end_date }}
                  </div>
                </div>
              </div>
            </div>

            <button type="submit" class="btn btn-primary rounded-pill">
              {{ $t("__submit") }}
            </button>
          </form>

          <div v-if="reportData.length === 0 && displayDailyReport === true">
            {{ $t('__noDate') }}
          </div>
          <b-table
                  v-if="reportData.length > 0"
                  responsive
                  striped
                  borderless
                  head-variant="light"
                  :empty-text="$t('__notData')"
                  :empty-filtered-text="$t('__notFindDevice')"
                  :items="reportData"
                  :fields="reportFields"
          >
            <template v-slot:cell(created_at)="data">
              {{
                $moment.tz(
                    data.item.detected_at != null ? data.item.detected_at : data.item.created_at,
                    'Asia/Taipei'
                ).format('YYYY-MM-DD HH:mm')
              }}
            </template>

            <template v-slot:cell(resident_id)="data">
              <span v-if="data.item.resident">
                {{ data.item.resident.id }}
              </span>
            </template>

            <template v-slot:cell(resident_name)="data">
              <span v-if="data.item.resident">
                {{ data.item.resident.name }}
              </span>
            </template>

            <template v-slot:cell(content)="data">
              <button
                  @click="editModal(data.item)"
                  type="button"
                  class="btn btn-link px-1 text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__edit')"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-clipboard-pulse" viewBox="0 0 24 24">
                  <path fill-rule="evenodd" d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5zm-2 0h1v1H3a1 1 0 0 0-1 1V14a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3.5a1 1 0 0 0-1-1h-1v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2m6.979 3.856a.5.5 0 0 0-.968.04L7.92 10.49l-.94-3.135a.5.5 0 0 0-.895-.133L4.232 10H3.5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 .416-.223l1.41-2.115 1.195 3.982a.5.5 0 0 0 .968-.04L9.58 7.51l.94 3.135A.5.5 0 0 0 11 11h1.5a.5.5 0 0 0 0-1h-1.128z"/>
                </svg>
              </button>
            </template>

          </b-table>
        </div>
      </div>
    </div>

    <!-- ECG 圖表 Modal -->
    <div
        class="modal fade cms-style"
        tabindex="-1"
        id="ecg-chart-modal"
        ref="ecgModel"
    >
      <div class="modal-dialog" :class="modalDialogClass" :style="modalStyle">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title fw-medium text-center">
              <span class="d-block">
                {{ $t('__ecgReport') }}
              </span>
            </h5>
            <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <h5 class="fw-medium text-center mb-3">
              {{ $t('__foraMeasurementTime') }} : {{ currentMeasurementTime }}
            </h5>
            <div class="ecg-chart-container position-relative">
              <div v-if="isChartLoading" class="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center bg-light bg-opacity-75">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <div ref="ecgChart" style="width: 100%; height: 400px;"></div>
            </div>
          </div>
          <div class="modal-footer">

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMenu from '@/components/layout/HeaderMenu.vue';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import i18n from '@/lang/lang.js';
import { resident } from '@/http/api/resident.js';
import { fora } from '@/http/api/fora.js';
import { mapMutations, mapState } from 'vuex';
import { select } from 'd3-selection';
import { scaleLinear } from 'd3-scale';
import { line } from 'd3-shape';
import { axisBottom, axisLeft } from 'd3-axis';
import { max, min } from 'd3-array';
import { Modal } from 'bootstrap';

export default {
    name: 'SearchReportResident',
    components: { HeaderMenu, VueCtkDateTimePicker },
    data() {
        return {
            resident: [],
            search: null,
            filter: null,
            reportFields: [
                {
                    key: 'created_at',
                    label: i18n.t('__foraMeasurementTime'),
                    class: 'align-middle'
                },
                {
                    key: 'resident_id',
                    label: i18n.t('__residentId'),
                    class: 'align-middle'
                },
                {
                    key: 'resident_name',
                    label: i18n.t('__residentName'),
                    class: 'align-middle'
                },
                {
                    key: 'content',
                    label: i18n.t('__measurementValue'),
                    class: 'align-middle'
                }
            ],
            collectedEvents: [],
            dailyForm: {
                resident_id: null,
                start_date: new Date().format('yyyy-MM-dd'),
                end_date: new Date().format('yyyy-MM-dd')
            },
            reportData: [],
            displayDailyReport: false,
            is_invalid: {
                start_date: false,
                end_date: false
            },
            currentEcgData: null,
            currentMeasurementTime: null,
            modalDialogClass: 'modal-lg',
            modalStyle: {},
            isChartLoading: false
        };
    },
    computed: {
        ...mapState(['token', 'timezone', 'userInfo', 'echoClient']),
        dailyMaxDate() {
            const vm = this;
            const hours = new Date(vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD HH:mm')).getHours();
            let maxDate = '';
            maxDate = hours >= 12
                ? vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD')
                : vm.$getTimeZoneDate(new Date().addDays(-1), vm.timezone, 'YYYY-MM-DD');
            return maxDate;
        },
        dailyMinDate() {
            const vm = this;
            const hours = new Date(vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD HH:mm')).getHours();
            let minDate = '';
            minDate = hours >= 12
                ? vm.$getTimeZoneDate(new Date().addDays(-29), vm.timezone, 'YYYY-MM-DD')
                : vm.$getTimeZoneDate(new Date().addDays(-30), vm.timezone, 'YYYY-MM-DD');
            return minDate;
        }
    },
    watch: {
        userInfo() {
            const vm = this;
            vm.getResident();
        }
    },
    methods: {
        ...mapMutations([
            'AlertsInfo',
            'Alerted'
        ]),
        getResident() {
            const vm = this;
            resident.get('', vm.token).then((res) => {
                if (res.status <= 201 && res.data.status === 'success') {
                    const data = res.data.data;
                    vm.resident = data;
                } else {
                    vm.AlertsInfo({
                        state: 'error',
                        title: i18n.t('__error'),
                        info: res.data.errors.toString()
                    });
                    vm.Alerted();
                }
            });
        },
        submitEcgReportForm() {
            fora.ecgReport(this.token, this.dailyForm).then((res) => {
                if (res.status <= 201 && res.data.status === 'success') {
                    this.reportData = res.data.data;
                    this.displayDailyReport = false;

                    if (this.reportData.length === 0) {
                        this.displayDailyReport = true;
                    }
                } else {
                    this.AlertsInfo({
                        state: 'error',
                        title: i18n.t('__error'),
                        info: res.data.errors.toString()
                    });
                    this.Alerted();
                }
            });
        },
        exportToCSV() {
            const headers = this.reportFields.map(field => field.label).join(',') + '\n';

            const rows = this.reportData.map(item => {
                return [
                    item.MPDateTime ? item.MPDateTime : '',
                    item.resident ? item.resident.id : '',
                    item.resident ? item.resident.name : '',
                    this.getTypeLabel(item.type),
                    item.fora_user ? item.fora_user.group_name : '',
                    this.getValueLabel(item)
                ].join(',');
            }).join('\n');

            const csvContent = headers + rows;
            const bom = '\uFEFF';

            const blob = new Blob([bom + csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', 'report_data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        getTypeLabel(type) {
            switch (type) {
            case 'glucose': return this.$t('__chilineDeviceGlucose');
            case 'oxygen': return this.$t('__chilineTypeOxygen');
            case 'temperature': return this.$t('__foraDeviceForeheadTemperature');
            case 'ear_temperature': return this.$t('__foraDeviceEarTemperature');
            case 'pressure': return this.$t('__chilineDevicePressure');
            case 'ketone': return this.$t('__chilineDeviceKetone');
            case 'uric_acid': return this.$t('__chilineDeviceUricAcid');
            case 'cholesterol': return this.$t('__chilineDeviceCholesterol');
            default: return this.$t('__chilineDeviceNoType');
            }
        },
        getValueLabel(item) {
            switch (item.type) {
            case 'glucose':
                return `${this.$t('__chilineDeviceGlucose')}: ${item.MValue1} / ${this.$t('__foraHemoglobin')}: ${item.MValue2 * 0.34} / ${this.$t('__foraHematocrit')}: ${item.MValue2}`;

            case 'oxygen':
                return `${this.$t('__foraAverageBloodOxygenLevel')}: ${item.MValue1} ${this.$t('__percent_min')} / ${this.$t('__foraHeartbeat')}: ${item.MValue2} ${this.$t('__times_min')}`;

            case 'temperature':
            case 'ear_temperature':
                return `${this.$t('__chilineDeviceTemperature')}: ${item.MValue1}`;

            case 'pressure':
                return `${this.$t('__foraDiastolicPressure')}: ${item.MValue1} ${this.$t('__foraPressureUnit')} / ${this.$t('__foraSystolicPressure')}: ${item.MValue2} ${this.$t('__foraPressureUnit')} / ${this.$t('__foraHeartbeat')}: ${item.MValue3} ${this.$t('__times_min')}`;

            case 'ketone':
                return `${this.$t('__foraKetone')}: ${item.MValue1}`;

            case 'uric_acid':
                return `${this.$t('__chilineDeviceUricAcid')}: ${item.MValue1}`;

            case 'cholesterol':
                return `${this.$t('__foraCholesterol')}: ${item.MValue1}`;

            default:
                return this.$t('__chilineDeviceNoType');
            }
        },
        async editModal(item) {
            if (item) {
                this.modalDialogClass = 'modal-lg';
                this.modalStyle = {};
                this.isChartLoading = true;
                this.currentMeasurementTime = this.$moment.tz(
                    item.detected_at != null ? item.detected_at : item.created_at,
                    'Asia/Taipei'
                ).format('YYYY-MM-DD HH:mm');

                var bootstrapModal = new Modal(this.$refs.ecgModel);
                bootstrapModal.show();

                await new Promise(resolve => {
                    this.$refs.ecgModel.addEventListener('shown.bs.modal', resolve, { once: true });
                });

                await this.getEgcChartData(item.id);
                this.displayEcgChart();
                this.isChartLoading = false;
            }
        },
        displayEcgChart() {
            if (!this.currentEcgData || !this.currentEcgData.length) {
                return;
            }

            const container = this.$refs.ecgChart;
            if (!container) {
                return;
            }

            // 清除舊的圖表
            select(container).selectAll('*').remove();

            // 設定圖表尺寸和邊距
            const margin = { top: 20, right: 20, bottom: 30, left: 50 };
            const containerWidth = container.offsetWidth || 800;
            const width = containerWidth - margin.left - margin.right;
            const height = 400 - margin.top - margin.bottom;

            try {
                // 建立 SVG 容器
                const svg = select(container)
                    .append('svg')
                    .style('width', '100%')
                    .style('height', '100%')
                    .attr('preserveAspectRatio', 'xMidYMid meet')
                    .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
                    .append('g')
                    .attr('transform', `translate(${margin.left},${margin.top})`);

                // 處理數據
                const data = this.currentEcgData
                    .filter(d => d.ch_lead_2 !== null)
                    .map(d => ({
                        x: d.idx / 300,
                        y: ((3300 * d.ch_lead_2 / 4095) - 1650) / 500
                    }));

                if (data.length === 0) {
                    return;
                }

                // 設定比例尺
                const xScale = scaleLinear()
                    .domain([0, max(data, d => d.x)])
                    .range([0, width]);

                const yScale = scaleLinear()
                    .domain([min(data, d => d.y), max(data, d => d.y)])
                    .range([height, 0]);

                // 繪製軸線
                svg.append('g')
                    .attr('transform', `translate(0,${height})`)
                    .call(axisBottom(xScale))
                    .append('text')
                    .attr('x', width)
                    .attr('y', -6)
                    .style('text-anchor', 'end')
                    .style('fill', 'black')
                    .text('時間 (秒)');

                svg.append('g')
                    .call(axisLeft(yScale))
                    .append('text')
                    .attr('transform', 'rotate(-90)')
                    .attr('y', 6)
                    .attr('dy', '.71em')
                    .style('text-anchor', 'end')
                    .style('fill', 'black')
                    .text('電壓 (mV)');

                // 建立線條生成器
                const ecgLine = line()
                    .defined(d => !isNaN(d.x) && !isNaN(d.y))
                    .x(d => xScale(d.x))
                    .y(d => yScale(d.y));

                // 繪製心電圖線條
                svg.append('path')
                    .datum(data)
                    .attr('fill', 'none')
                    .attr('stroke', '#2CBDC0')
                    .attr('stroke-width', 1.5)
                    .attr('d', ecgLine);
            } catch (error) {
                console.error('繪製圖表時發生錯誤：', error);
            }
        },
        async getEgcChartData(id) {
            const vm = this;
            try {
                const res = await fora.ecgReportDetail(id, vm.token);
                if (res.status <= 201 && res.data.status === 'success') {
                    this.modalDialogClass = 'modal-lg';
                    this.modalStyle = {};
                    // 2401 代表 8 秒資料
                    if (res.data.data.ecg_data.length > 2401) {
                        this.modalDialogClass = '';
                        this.modalStyle = { width: '80%', maxWidth: '80%' };
                    }
                    vm.currentEcgData = res.data.data.ecg_data;
                    await this.$nextTick();
                } else {
                    vm.AlertsInfo({
                        state: 'error',
                        title: i18n.t('__error'),
                        info: res.data.errors.toString()
                    });
                    vm.Alerted();
                }
            } catch (error) {
                console.error(error);
                vm.AlertsInfo({
                    state: 'error',
                    title: i18n.t('__error'),
                    info: error.message
                });
                vm.Alerted();
            }
        }
    },
    created() {
    },
    mounted() {
        if (this.userInfo) {
            this.getResident();
        }
    }
};
</script>

<style lang="scss">
.fora-device {
  .card {
    position: relative;

    &-body {
      position: relative;
      z-index: 1;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("~@/assets/images/icon.svg");
      background-repeat: no-repeat;
      background-position: calc(100% + 20px) calc(100% + 20px);
      background-size: auto;
      opacity: 0.1;
      z-index: 0;
    }
  }
}

.ecg-chart-container {
  width: 100%;
  height: 400px;
  margin: 0 auto;
}
</style>
